exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-case-studies-old-js": () => import("./../../../src/pages/case-studies-old.js" /* webpackChunkName: "component---src-pages-case-studies-old-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-early-bird-js": () => import("./../../../src/pages/early-bird.js" /* webpackChunkName: "component---src-pages-early-bird-js" */),
  "component---src-pages-features-charter-base-operations-js": () => import("./../../../src/pages/features/charter-base-operations.js" /* webpackChunkName: "component---src-pages-features-charter-base-operations-js" */),
  "component---src-pages-features-guest-experience-app-js": () => import("./../../../src/pages/features/guest-experience-app.js" /* webpackChunkName: "component---src-pages-features-guest-experience-app-js" */),
  "component---src-pages-features-inspections-and-rounds-tsx": () => import("./../../../src/pages/features/inspections-and-rounds.tsx" /* webpackChunkName: "component---src-pages-features-inspections-and-rounds-tsx" */),
  "component---src-pages-features-maintenance-js": () => import("./../../../src/pages/features/maintenance.js" /* webpackChunkName: "component---src-pages-features-maintenance-js" */),
  "component---src-pages-features-mobile-check-in-js": () => import("./../../../src/pages/features/mobile-check-in.js" /* webpackChunkName: "component---src-pages-features-mobile-check-in-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-reserve-my-spot-js": () => import("./../../../src/pages/reserve-my-spot.js" /* webpackChunkName: "component---src-pages-reserve-my-spot-js" */),
  "component---src-pages-solutions-for-charter-operators-js": () => import("./../../../src/pages/solutions/for-charter-operators.js" /* webpackChunkName: "component---src-pages-solutions-for-charter-operators-js" */),
  "component---src-pages-solutions-for-crewed-yachts-js": () => import("./../../../src/pages/solutions/for-crewed-yachts.js" /* webpackChunkName: "component---src-pages-solutions-for-crewed-yachts-js" */),
  "component---src-pages-solutions-for-yacht-management-js": () => import("./../../../src/pages/solutions/for-yacht-management.js" /* webpackChunkName: "component---src-pages-solutions-for-yacht-management-js" */)
}

