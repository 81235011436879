module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-mdx@2.15.0_@mdx-js+mdx@1.6.22_@mdx-js+react@1.6.22_gatsby@4.25.8_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/render/project/src","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@5.25.0_gatsby@4.25.8_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_babel-plugin-styled-components@2.1.4_gatsby@4.25.8_rea_p6imvuhpbhfutxfmepkuaroski/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.8_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"efc649e13be5482696bf47d7f5adecd5"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-anchor-links@1.2.1/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.8_babel-eslint@10.1.0_react-dom@17.0.2_react@17.0.2_typescript@5.4.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
